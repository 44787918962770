import { useState, useEffect } from "react";

import {   useWeb3ModalProvider,
  useWeb3ModalAccount,
createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { parseUnits,formatEther,BrowserProvider, Contract,JsonRpcProvider, formatUnits, parseEther } from "ethers";
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment'
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import {  Link } from 'react-router-dom';
import {
    tokenabi,
    tokenaddress,
      wbtc,
      weth,
      busd,
      usdt,
      usdc,
      erc20abi,
    contract_abi,
    contract_address,
    usdt_abi,
    usdt_address,
    RBC_token,
    RBC_abi,
    projectId,
    chainnetwork
} from "./constant";
 
import { Sidebar } from './Sidebar'
import Web3 from 'web3'

export function Dashboard() {
  var web3 = new Web3(chainnetwork);
	const { address, chainId, isConnected } = useWeb3ModalAccount();
	const { walletProvider } = useWeb3ModalProvider();
    const [bnbPrice , setbnbPrice] = useState();
    const [btcPrice , setbtcPrice] = useState();
    const [tokenPrice , settokenPrice] = useState();
    const [ethPrice , setethPrice] = useState();
    const [ selectedCurrencyUserBalance , setselectedCurrencyUserBalance] = useState();
	const [ userUkcBalance , setuserUkcBalance] = useState(0);
	const [ selectedCurrency , setSelectedCurrency] = useState();
	const [ input , setinput ] = useState();
	const [ tokenInput , settokenInput ] = useState();
	const [ ukcInput , setukcInput ] = useState();
    const [approval , setapproval] = useState();
    const [btn , setbtn] = useState();
	const [showNoti , setshowNoti] = useState("none");
	const [notiMessage , setnotiMessage] = useState("none");
	const [notiClass , setnotiClass] = useState("danger");
	const [buyBtnText, setbuyBtnText] = useState("Select Currency");
    const [userId, setUserId] = useState("");
    const [connectedWalletAddr,setConnectedWalletAddr] = useState();
    const [userPackages,setUserPackages] = useState();
    const [packages,setPackages] = useState();
    const [contractObj,setContractObj] = useState();
    const [usdtContractObj,setUsdtContractObj] = useState();
    const [rbcContractObj,setRbcContractObj] = useState();
    const [parentAddr,setParentAddr] = useState();
	  const [baseURL,setBaseURL] = useState(window.location.origin)
    const [refUrl,setRefUrl] = useState()
    const [refUrlShow,setRefUrlShow] = useState()
    const [rbcTotal,setRbcTotal] = useState('Loading..')
    const [totalIncome,setTotalIncome] = useState('Loading..')
    const [totalDrained,setTotalDrained] = useState('Loading..')
    const [totalMissedIncome,setTotalMissedIncome] = useState('Loading..')
    const [availableBalance,setAvailableBalance] = useState('Loading..')
    const [availableBalanceReal,setAvailableBalanceReal] = useState('Loading..')
    const [userDirectCount,setUserDirectCount] = useState('Loading..')
    const [teamCount,setTeamCount] = useState('Loading..')
    
    

    
   

  
    // 2. Set chains
    const mainnet ={
      chainId: 137,
      chainIdHex:'0x89',
      name: 'Polygon Smart Chain',
      currency: 'MATIC',
      explorerUrl: 'https://bscscan.com',
      rpcUrl: 'https://polygon-rpc.com'
      /*
      chainId: 97,
      name: 'Binance Smart Chain Testnet',
      currency: 'tBNB',
      explorerUrl: 'https://testnet.bscscan.com',
      rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/'*/
    }
  
    const bscRpc = "https://polygon-rpc.com/";
    const validChainId = 137;
    // 3. Create modal
    const metadata = {
      name: 'uckccoin',
      description: 'uckccoin',
      url: 'https://test.ukccoin.io/', // origin must match your domain & subdomain
      icons: ['https://test.ukccoin.io/html/images/UKC_Logo.svg']
    }
  
  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [mainnet],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })
  
  
  const getSignerOrProvider = async (needSigner = false) => {
    let ethersProvider = new BrowserProvider(walletProvider);
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }
  
      
      const signer = await ethersProvider.getSigner();
  
      if (needSigner) {
        const signer = await ethersProvider.getSigner();
  
        return signer;
      }
  
      return signer;
    } catch (error) {
      console.error("Error in getWeb3Provider:", error);
      throw error; // Propagate the error to the caller or handle as needed
    }
  };
  
  


  const resetNotification = () => {
	  setTimeout(() => {
		  setshowNoti("none");
		}, 10000);
  }
  
  const updateNotification = (nMessage,nClass,nDisplay) => {
		setnotiMessage(nMessage);
		setnotiClass(nClass);
		setshowNoti(nDisplay);
		resetNotification();
  }
  




const checkAllowence = async() => {

  return  await usdtContractObj.allowance(connectedWalletAddr,contract_address);
}


const Approve=async (amount)=>{

  // if(window.ethereum.chainId!=chainId)
  //     {
  //     await window.ethereum.request({
  //        method: 'wallet_switchEthereumChain',
  //        params: [{ chainId }]
  //      })
  //        .catch((error) =>{toast.error(`Please switching to ${networkName} network.`,'Failed');return setTimeout(()=>location.reload(),1000)});
  //     }


try{
  amount = 11000000000000*1000000;
  amount = amount.toString();
  await usdtContractObj.approve(contract_address,amount);
  setTimeout(()=>{toast.success('Transaction Approved.','Approved');
  setTimeout(()=>window.location.reload(),700)},15000);
  toast.info('Please wait for Approving.','Processing...');
}
catch(e){
  toast.error(e);
}
  return ;
}


const checkBalanceOf=async(walletAddress)=>{

  return  await usdtContractObj.balanceOf(walletAddress);
}

const buyNow = async(pack) =>{
  try {
    if(pack<=userPackages.length)
    {
      toast.error('Already have package.');
     
        return setTimeout(()=>window.location.reload(),1000);
    }
    else if(pack!=(userPackages.length+1))
    {
        toast.error('Invalid package.');
        // toast('🦄 Wow so easy!', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   transition: Bounce,
        //   });
        return setTimeout(()=>window.location.reload(),1000);
    }
 

    let packAmt=await contractObj.approvalAmount(pack);

    packAmt=parseInt(packAmt)+(parseInt(packAmt)*10/100);
    
    let whitelist= await contractObj.whitelist(connectedWalletAddr);

     
    if(parseInt(whitelist) < pack)
    {
    
    const checkAllow=await checkAllowence(connectedWalletAddr);

    if( parseInt(checkAllow)/10**6 < Number(packAmt)) //web3.utils.fromWei(checkAllow)
    {
        //$(id).text('Approve');
        await Approve(packAmt);
         setTimeout(()=>window.location.reload(),1000);
        return toast.success('Transaction successful.','Approved');
    }

    const balance = await checkBalanceOf(connectedWalletAddr);
       console.log(Number(balance)/10**6);

    if(Number(balance)/10**6 < Number(packAmt))
       { setTimeout(() => window.location.reload(), 1000); return toast.error('Insufficient Balance.');}

    }


    await contractObj.buyPackage(pack);
    toast.success('Transaction Successful.');
    setTimeout(() => window.location.reload(), 1000);
  }
  catch(e){
    toast.error(e);
  }


}
const packageHtml = () => {

    var allHtml=[];
    const path=window.location.pathname.split('/')[1];
    const prevcheck='preview';

    if(isConnected) {
    if(userPackages!==undefined && packages!==undefined) {
    for (let i = 0; i < packages.length; i++) {
        if((userPackages.length>i)  && userPackages[i]==packages[i])
        allHtml.push( 
            <div className="col-6 col-md-3 col-sm-4 mb-4 ">
                <div className="dash_box h100 disabled">
                    <h4>{Number(packages[i])+(Number(packages[i])*10/100)} $</h4>
                    <span className="trophy4 mb-3"><img src="rabito/img/logo.svg" alt="logo" /></span><br/>
                    <button className="btn btn-action btn_man_small" disabled><span>Activated</span></button>
                </div>
            </div>
        );
        else if(userPackages.length==i)
        allHtml.push( 
        <div className="col-6 col-md-3 col-sm-4 mb-4 ">
            <div className="dash_box h100 ${path==prevcheck?'disabled':''}">
                <h4>{Number(packages[i])+(Number(packages[i])*10/100)} $</h4>
                <span className="trophy4 mb-3"><img src="rabito/img/logo.svg" alt="logo" /></span><br/>
                {path!==prevcheck? <button className="btn btn-action btn_man_small buy-package" onClick={()=>buyNow(i+1)} package={i+1}><span>Buy Now</span></button> : <button className="btn btn-action btn_man_small" disabled><span>Not Active</span></button>}
            </div>
        </div>)
        else
        allHtml.push( 
        <div className="col-6 col-md-3 col-sm-4 mb-4 ">
        <div className="dash_box h100 disabled">
            <h4>{Number(packages[i])+(Number(packages[i])*10/100)} $</h4>
            <span className="trophy4 mb-3"><img src="rabito/img/logo.svg" alt="logo" /></span><br/>
            {path!==prevcheck?<button className="btn btn-action btn_man_small disabled"><span>Buy Now</span></button>: <button className="btn btn-action btn_man_small" disabled><span>Not Active</span></button>}
        </div>
        </div>
        );
    }
   }

  }
  else {
    if(userPackages!==undefined && packages!==undefined) {
      for (let i = 0; i < packages.length; i++) {
          if((userPackages.length>i)  && userPackages[i]==packages[i])
          allHtml.push( 
              <div className="col-6 col-md-3 col-sm-4 mb-4 ">
                  <div className="dash_box h100 disabled">
                      <h4>{Number(packages[i])+(Number(packages[i])*10/100)} $</h4>
                      <span className="trophy4 mb-3"><img src="rabito/img/logo.svg" alt="logo" /></span><br/>
                      <button className="btn btn-action btn_man_small" disabled><span>Not Active</span></button>
                  </div>
              </div>
          );
          else if(userPackages.length==i)
          allHtml.push( 
          <div className="col-6 col-md-3 col-sm-4 mb-4 ">
              <div className="dash_box h100 disabled">
                  <h4>{Number(packages[i])+(Number(packages[i])*10/100)} $</h4>
                  <span className="trophy4 mb-3"><img src="rabito/img/logo.svg" alt="logo" /></span><br/>
                  <button className="btn btn-action btn_man_small" disabled><span>Not Active</span></button>
              </div>
          </div>)
          else
          allHtml.push( 
          <div className="col-6 col-md-3 col-sm-4 mb-4 ">
          <div className="dash_box h100 disabled">
              <h4>{Number(packages[i])+(Number(packages[i])*10/100)} $</h4>
              <span className="trophy4 mb-3"><img src="rabito/img/logo.svg" alt="logo" /></span><br/>
              <button className="btn btn-action btn_man_small" disabled><span>Not Active</span></button>
          </div>
          </div>
          );
      }
     }
  }
   return allHtml;
}

function copyToClipboardData(text) {
  var tempInput = document.createElement("input");
  tempInput.value = text;
  document.body.appendChild(tempInput);
  tempInput.select();
  tempInput.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(tempInput);
}

const copyToClipboard = async() =>{
     copyToClipboardData(refUrl);
      toast.success('Link copied to clipboard');
   
    
}



  const Team = async(userIdGet,contractObjGet) => {

    setTeamCount('Loading...');
   var count=0;
   async function helper(userIdNewGet,contractObjGet)
   {
     try {
       var userDirects= await contractObjGet.userDirectFetch(userIdNewGet);
       count+=userDirects.length;
       setTeamCount(count);
       if(userDirects.length==0){
        return true;
        }
        else {
          for (let i of userDirects) {
            await helper(i,contractObjGet);
         }
        }

      } 
      catch(e){
        console.log (e);
      }
        
        
   }
   await helper(userIdGet,contractObjGet);
  
  
}

  const todayIncomeData = async() =>{
    $('#todayIncome').html('<small>Loading...</small>');
   
    var currentDate=new Date(Date.now());
    //var yesterday = new Date(new Date(). getTime() - (24 * 60 * 60 * 1000)); 
    var directIncome=0;
    var drainIncome=0;
    var spotIncome=0;

  



    if(isConnected){

    var DirectData= await contractObj.getDataDirectData(userId);
    console.log('DirectData',DirectData.length);
    var DrainData= await contractObj.getDrainData(userId);
    console.log('DrainData',DrainData.length);
    var SpotData= await contractObj.getSpotsData(userId);
    console.log('SpotData',SpotData.length);
    }
    else {

      var DirectData= await contractObj.methods.getDataDirectData(userId).call();
      console.log('DirectData',DirectData.length);
      var DrainData= await contractObj.methods.getDrainData(userId).call();
      console.log('DrainData',DrainData.length);
      var SpotData= await contractObj.methods.getSpotsData(userId).call();
      console.log('SpotData',SpotData.length);
    }

    var currentDate=new Date(Date.now());
    //var yesterday = new Date(new Date(). getTime() - (24 * 60 * 60 * 1000)); 
    var directIncome=0;
    var drainIncome=0;
    var spotIncome=0;

    for(let i =DirectData.length-1;i>=0;i--)
    {
        let d= new Date(Number(DirectData[i][3])*1000);

        if(d.toDateString()==currentDate.toDateString())
        //if( d <= currentDate && d >=yesterday)
          directIncome+=Number(parseInt(DirectData[i][2])/(10**6));
        else
          break;
    }

    for(let i =DrainData.length-1;i>=0;i--)
    {
        let d= new Date(Number(DrainData[i][3])*1000);

        if(d.toDateString()==currentDate.toDateString())
        //if( d <= currentDate && d >=yesterday)
        drainIncome+=Number(parseInt(DrainData[i][2])/(10**6));
        else
          break;
    }
    for(let i =SpotData.length-1;i>=0;i--)
    {
        let d= new Date(Number(SpotData[i][4])*1000);

        if(d.toDateString()==currentDate.toDateString())
        //if( d <= currentDate && d >=yesterday)
        spotIncome+=Number(parseInt(SpotData[i][2])/(10**6));
        else
          break;
    }
    if((directIncome+drainIncome+spotIncome)>0)
      $('#up-i-arr').addClass('text-success');
    
    $('#todayIncome').text(Number(directIncome+drainIncome+spotIncome).toFixed(4)+' $');
  }


  const todayMissedIncomeData = async() =>{
    
    $('#todayMissedIncome').html('<small>Loading...</small>');
    var currentDate=new Date(Date.now());
   
    if(isConnected){
    var MissedDirectData= await contractObj.getDataMissedDirectData(userId);
    console.log('MissedDirectData',MissedDirectData.length);
    var MissedDrainData= await contractObj.getMissedDrainData(userId);
    console.log('MissedDrainData',MissedDrainData.length);
    var MissedSpotData= await contractObj.getMissedSpotsIncomeData(userId);
    console.log('MissedSpotData',MissedSpotData.length);
    }
    else {
      var MissedDirectData= await contractObj.methods.getDataMissedDirectData(userId).call();
      console.log('MissedDirectData',MissedDirectData.length);
      var MissedDrainData= await contractObj.methods.getMissedDrainData(userId).call();
      console.log('MissedDrainData',MissedDrainData.length);
      var MissedSpotData= await contractObj.methods.getMissedSpotsIncomeData(userId).call();
      console.log('MissedSpotData',MissedSpotData.length);
    }

    var missedDirectIncome=0;
    var missedDrainIncome=0;
    var missedSpotIncome=0;

    for(let i =MissedDirectData.length-1;i>=0;i--)
    {
        let d= new Date(Number(MissedDirectData[i][3])*1000);

        if(d.toDateString()==currentDate.toDateString())
        missedDirectIncome+=Number(parseInt(MissedDirectData[i][2])/(10**6));
        else
          break;
    }


    for(let i =MissedDrainData.length-1;i>=0;i--)
    {
        let d= new Date(Number(MissedDrainData[i][3])*1000);

        if(d.toDateString()==currentDate.toDateString())
        missedDrainIncome+=Number(parseInt(MissedDrainData[i][2])/(10**6));
        else
          break;
    }

    for(let i =MissedSpotData.length-1;i>=0;i--)
    {
        let d= new Date(Number(MissedSpotData[i][4])*1000);

        if(d.toDateString()==currentDate.toDateString())
        missedSpotIncome+=Number(parseInt(MissedSpotData[i][2])/(10**6));
        else
          break;
    }

    if((missedDirectIncome+missedDrainIncome+missedSpotIncome)>0)
    $('#up-m-arr').addClass('text-danger');

    $('#todayMissedIncome').text(Number(missedDirectIncome+missedDrainIncome+missedSpotIncome).toFixed(4)+' $');
  }

  const getUserData = async (walletAddr,contractObjGet,rbcContractObjGet) => {
    try {
      if(walletAddr){
        var cuUserId = await contractObjGet.userId(walletAddr);
        cuUserId = parseInt(cuUserId);
        setUserId(cuUserId);
  
        
        let userPackages = await contractObjGet.getuserPackages(cuUserId);
        setUserPackages(userPackages);
        //console.log(userPackages);
    
        const packages=await contractObjGet.Allpackages(); 
        setPackages(packages);

        const parent=await contractObjGet.referal(walletAddr);
        let parentShow = parent.substr(0,5)+"..............."+parent.substr(-5);
        setParentAddr(parentShow);
        let refUrl = baseURL+'/register/'+walletAddr;
        const refUrlShowTo = refUrl.substr(0,10)+".........."+refUrl.substr(-5)
        setRefUrl(refUrl);
        setRefUrlShow(refUrlShowTo);


        let total_rabito= await rbcContractObjGet.balanceOf(walletAddr);
        total_rabito = parseInt(total_rabito);
        total_rabito = Number((Number(total_rabito)/10**18)).toFixed(4)
        setRbcTotal(total_rabito);   
        
        
      
        const userDirects= await contractObjGet.userDirectFetch(cuUserId);
        setUserDirectCount(userDirects.length)
        
        const DirectIncome = parseInt(await contractObjGet.getDataDirect(cuUserId));
        const DirectMissed = parseInt(await contractObjGet.getDataMissed(cuUserId));
        const DrainIncome = parseInt(await contractObjGet.getDrainIncome(cuUserId));
        const MissedDrain = parseInt(await contractObjGet.getMissedDrainIncome(cuUserId));
      

        var spotIncome=0;
        var missedSpotIncome=0;
        for(let i=0;i<10;i++){
            spotIncome+= parseInt(await contractObjGet.getSpotsIncome(cuUserId,i));
            missedSpotIncome+= parseInt(await contractObjGet.getMissedSpotsIncome(cuUserId,i));
           
        }

        let totalIncomeData = DirectIncome+spotIncome+DrainIncome;
        totalIncomeData = Number(totalIncomeData/(10**6)).toFixed(4);
        setTotalIncome(totalIncomeData);
        let totalDrainedData = (DirectIncome+spotIncome)*10/100;
        totalDrainedData = Number(totalDrainedData/(10**6)).toFixed(4);
        setTotalDrained(totalDrainedData);
        let totalMissedIncomeData = DirectMissed+missedSpotIncome+MissedDrain;
        totalMissedIncomeData = Number(totalMissedIncomeData/(10**6)).toFixed(4);
        setTotalMissedIncome(totalMissedIncomeData)


        
        






       

        Team(cuUserId,contractObjGet);

      }
      

    } catch (err) {
      console.error(err);
    }
  };


  const accountsChanged =() =>{
    window.location.reload();
  }

  const chainChanged =() =>{
    window.location.reload();

  }

  const isRegistered = async()=>{
    //if(!contractObj){
      const signer = await getSignerOrProvider(true);
      const provider = await getSignerOrProvider();
      console.log('provider',provider);
      console.log('signer',signer);
       var contractObjData = new Contract(contract_address,contract_abi, provider);
       setContractObj(contractObjData);
      var usdtContractObjData = new Contract(usdt_address,usdt_abi, provider);
      setUsdtContractObj(usdtContractObjData);
      var rbcContractObjData = new Contract(RBC_token,RBC_abi, provider);
      setRbcContractObj(rbcContractObjData);

      

      setConnectedWalletAddr(signer.address);
      const checkIt = await contractObjData.isRegistered(signer.address);

      if(checkIt==true){
          getUserData(signer.address,contractObjData,rbcContractObjData);
        
      }
      else {
          window.location.href="/";
      }
    //}
  }



  

  const TeamWeb3 = async(userIdGet,contractObjGet) => {

    setTeamCount('Loading...');
   var count=0;
   async function helperWeb3(userIdNewGet,contractObjGet)
   {
     try {
       var userDirects= await contractObjGet.methods.userDirectFetch(userIdNewGet).call();
       count+=userDirects.length;
       setTeamCount(count);
       if(userDirects.length==0){
        return true;
        }
        else {
          for (let i of userDirects) {
            await helperWeb3(i,contractObjGet);
         }
        }

      } 
      catch(e){
        console.log (e);
      }
        
        
   }
   await helperWeb3(userIdGet,contractObjGet);
  
  
}


  const getUserDataWeb3 = async (walletAddr,contractObjGet,rbcContractObjGet) => {
    try {
      if(walletAddr){
        var cuUserId = await contractObjGet.methods.userId(walletAddr).call();
        cuUserId = parseInt(cuUserId);
        setUserId(cuUserId);
  
        
        let userPackages = await contractObjGet.methods.getuserPackages(cuUserId).call();
        setUserPackages(userPackages);
        //console.log(userPackages);
    
        const packages=await contractObjGet.methods.Allpackages().call(); 
        setPackages(packages);

        const parent=await contractObjGet.methods.referal(walletAddr).call();
        let parentShow = parent.substr(0,5)+"..............."+parent.substr(-5);
        setParentAddr(parentShow);
        let refUrl = baseURL+'/register/'+walletAddr;
        const refUrlShowTo = refUrl.substr(0,10)+".........."+refUrl.substr(-5)
        setRefUrl(refUrl);
        setRefUrlShow(refUrlShowTo);


        let total_rabito= await rbcContractObjGet.methods.balanceOf(walletAddr).call();
        total_rabito = parseInt(total_rabito);
        total_rabito = Number((Number(total_rabito)/10**18)).toFixed(4)
        setRbcTotal(total_rabito);   
        
        
      
        const userDirects= await contractObjGet.methods.userDirectFetch(cuUserId).call();
        setUserDirectCount(userDirects.length)
        
        const DirectIncome = parseInt(await contractObjGet.methods.getDataDirect(cuUserId).call());
        const DirectMissed = parseInt(await contractObjGet.methods.getDataMissed(cuUserId).call());
        const DrainIncome = parseInt(await contractObjGet.methods.getDrainIncome(cuUserId).call());
        const MissedDrain = parseInt(await contractObjGet.methods.getMissedDrainIncome(cuUserId).call());
      

        var spotIncome=0;
        var missedSpotIncome=0;
        for(let i=0;i<10;i++){
            spotIncome+= parseInt(await contractObjGet.methods.getSpotsIncome(cuUserId,i).call());
            missedSpotIncome+= parseInt(await contractObjGet.methods.getMissedSpotsIncome(cuUserId,i).call());
           
        }

        let totalIncomeData = DirectIncome+spotIncome+DrainIncome;
        totalIncomeData = Number(totalIncomeData/(10**6)).toFixed(4);
        setTotalIncome(totalIncomeData);
        let totalDrainedData = (DirectIncome+spotIncome)*10/100;
        totalDrainedData = Number(totalDrainedData/(10**6)).toFixed(4);
        setTotalDrained(totalDrainedData);
        let totalMissedIncomeData = DirectMissed+missedSpotIncome+MissedDrain;
        totalMissedIncomeData = Number(totalMissedIncomeData/(10**6)).toFixed(4);
        setTotalMissedIncome(totalMissedIncomeData)


        
        






       

        TeamWeb3(cuUserId,contractObjGet);

      }
      

    } catch (err) {
      console.error(err);
    }
  };

  const unRegistered = async()=>{
 
    var contractObjData = new web3.eth.Contract(contract_abi,contract_address);
    setContractObj(contractObjData);
   var usdtContractObjData = new web3.eth.Contract(usdt_abi,usdt_address);
   setUsdtContractObj(usdtContractObjData);
   var rbcContractObjData = new web3.eth.Contract(RBC_abi,RBC_token);
   setRbcContractObj(rbcContractObjData);

   var walletAddress = sessionStorage.getItem('preview_adr');

   setConnectedWalletAddr(walletAddress);
  
   const checkIt = await contractObjData.methods.isRegistered(walletAddress).call();
  
   if(checkIt==true){
       getUserDataWeb3(walletAddress,contractObjData,rbcContractObjData);
     
   }
   else {
       window.location.href="/";
   }

}


useEffect( () => {

  if (isConnected) {
   
    walletProvider.on('accountsChanged', accountsChanged); 
    walletProvider.on('chainChanged', chainChanged);
    isRegistered();

  }
  else {
    unRegistered();
  }
    
    if(!isConnected){
      console.log("b");
        setSelectedCurrency("");
        setukcInput("");
        settokenInput("");
        setuserUkcBalance("");
        setselectedCurrencyUserBalance("");
        $("#currency_id").val("");
        $("#table_body").html();
       // window.location.href="/";
    }
},[isConnected]);

useEffect(() => {
 
  const videos = document.querySelectorAll('.video-player');
  
  videos.forEach((video) => {
    video.play().catch((error) => {
      console.error('Error playing video:', error);
    });
  });
}, []);

  return (<div>
   <div className="top_bar">
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText">
      <span className="icon-bar"><i className="fa fa-bars"></i></span>
  </button>
    <div className="row align-items-center"> 
        <div className="col-4">
          <Link className="navbar-brand" href="/dashboard"><img src="rabito/img/logo.svg" alt="header-Logo" className="logo2" /></Link>
        </div>
        <div className="col-auto ml-auto">
        {(isConnected) ? <w3m-button balance="hide" label="Connect Wallet"/> : <Link className="btn btn-action btn_man_small  ml-auto" to='/'>Home</Link> }
        </div>
</div>
</div>
<div className="dash_bar_man">
  <div className="left_bar">
  







  <Sidebar/>
  </div>
  <div className="right_bar">
     <div className="row row5">
        <div className="col-md-6 mb-4 ">
             <div className="dash_box">
               <div className="d-flex align-items-center">
                   <div className="img_box">
                  <img alt="" className="max-h-full" src="rabito/img/user.png"/>
               </div>
               <div className="invited_box">
                 <h3>User ID {userId}</h3>
                 <h5>Sponsor Address</h5>
                 <h6 className="m-0">{parentAddr}</h6>
                
               </div>    
             </div>
             </div>
           </div>
           <div className="col-md-6 mb-4 ">
               <div className="dash_box h100">
                  <div className="row">
                    <div className="col-md-6 pb-4 pb-md-0">
                      <h6 className="d_hadding mb-4">Personal link </h6>
                      <div className="d-flex align-items-center">
                        <h4 className="color1">{refUrlShow}</h4>
                       
                      </div>
                      <button className="btn btn-action btn_man_small  ml-auto" onClick={copyToClipboard}><span>Copy</span></button>
                    </div>
                    <div className="col-md-6 text-md-right">
                      <h6 className="d_hadding mb-4">Total Free RBC </h6>
                      <div className="d-flex d-md-block  align-items-center ">
                        <h4 className="fw600" id="total_rabito">{rbcTotal}</h4>
                        <img className="ml-auto mb-1" src="rabito/img/logo-icon.svg" alt="logo" />
                      </div>
                    </div>
                    </div>  
              </div>
           </div>


           <div className="col-md-6 mb-4 ">
            <video  autoplay loop muted playsinline  style={{maxWidth:'100%'}} className="video-player rounded">
            <source src="/rabito/r2.mp4" type="video/mp4"/>
            </video>
          </div>

            <div class="col-md-6 mb-4">

                <div class="dash_box h100 mh140">
                  <div class="row">
                    <div class="col-auto pr-0">
                      <h6 class="d_hadding">Partner </h6>
                      <h4 class="fw600" id="direct">{userDirectCount}</h4>
                      <img class="ml-auto" src="rabito/img/logo-icon.svg" alt="logo" />
                    </div>
                    <div class="col-auto text-right ml-auto pl-0">
                      <h6 class="d_hadding">Coworker </h6>
                      <h4 class="fw600" id="Team">{teamCount}</h4>
                      <img class="ml-auto" src="rabito/img/logo-icon.svg" alt="logo"/>
                    </div>
                  </div>
              </div>
            </div>
   
           <div className="col-md-6  mb-4 ">
            <div className="dash_box h100 mh140">
            <div className="row">
            <div className="col-auto pr-0">
            
                  <h6 className="d_hadding">USDT Earning </h6>
                  <h4 className="fw600" id="totalIncome">{totalIncome} $</h4>



                     <p className="text-center text-success  my-3 border rounded border-success">Today's Earning</p>
                    <div class="dash_box_bottom active d-dlex ">
                          <i class="fa fa-arrow-up mr-2" id="up-i-arr"></i> <span id="todayIncome">0 $</span>&nbsp; <span style={{cursor:'pointer',color:'#00C7EF'}} onClick={todayIncomeData}><i class="fa fa-refresh" > </i>  
                          <small className="ml-1 text-white"> Click Here</small></span>
                    </div>
                      </div>

                  
                    
                      <div className="col-auto ml-auto text-right pl-0">

                          <h6 className="d_hadding">USDT Missing </h6>
                          <h4 className="fw600" id="totalMissedIncome">{totalMissedIncome} $</h4>
                          <p className="text-center text-danger  my-3 border rounded border-danger">Today's Missing</p>
                     
                          <div class="dash_box_bottom active d-dlex ">
                            <i class="fa fa-arrow-up mr-2" id="up-m-arr"></i> <span id="todayMissedIncome">0 $</span>&nbsp; <span  style={{cursor:'pointer',color:'#00C7EF'}} ><i class="fa fa-refresh" onClick={todayMissedIncomeData}></i>  <small className="ml-1 text-white"> Click Here</small></span>  
                         </div>
                        </div>
                        </div>
            </div>
          </div>

     
          <div className="col-md-6 mb-4 ">
  <video  autoplay loop muted playsinline style={{maxWidth:'100%'}} className=" video-player rounded">
      <source src="/rabito/r1.mp4" type="video/mp4"/>
      </video>
   
</div>
    
   </div>

   <div className=" mb-4 ">
       <div className="text-center">
           <div className="row row5 justify-content-center">
               
                {packageHtml()}

                
             
            </div>
       </div>
   </div>


 
</div>



        
  </div>
 </div>

  );
}
